// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
import { z } from 'zod';
import { oneTimeTokenContextGuard, oneTimeTokenStatusGuard } from './../foundations/index.js';
const createGuard = z.object({
    tenantId: z.string().max(21).optional(),
    id: z.string().min(1).max(21),
    email: z.string().min(1).max(128),
    token: z.string().min(1).max(256),
    context: oneTimeTokenContextGuard.optional(),
    status: oneTimeTokenStatusGuard.optional(),
    createdAt: z.number().optional(),
    expiresAt: z.number(),
});
const guard = z.object({
    tenantId: z.string().max(21),
    id: z.string().min(1).max(21),
    email: z.string().min(1).max(128),
    token: z.string().min(1).max(256),
    context: oneTimeTokenContextGuard,
    status: oneTimeTokenStatusGuard,
    createdAt: z.number(),
    expiresAt: z.number(),
});
export const OneTimeTokens = Object.freeze({
    table: 'one_time_tokens',
    tableSingular: 'one_time_token',
    fields: {
        tenantId: 'tenant_id',
        id: 'id',
        email: 'email',
        token: 'token',
        context: 'context',
        status: 'status',
        createdAt: 'created_at',
        expiresAt: 'expires_at',
    },
    fieldKeys: [
        'tenantId',
        'id',
        'email',
        'token',
        'context',
        'status',
        'createdAt',
        'expiresAt',
    ],
    createGuard,
    guard,
    updateGuard: guard.partial(),
});
