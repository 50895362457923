// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
import { z } from 'zod';
import { templateTypeGuard, emailTemplateDetailsGuard } from './../foundations/index.js';
const createGuard = z.object({
    tenantId: z.string().max(21).optional(),
    id: z.string().min(1).max(21),
    languageTag: z.string().min(1).max(16),
    templateType: templateTypeGuard,
    details: emailTemplateDetailsGuard,
    createdAt: z.number().optional(),
});
const guard = z.object({
    tenantId: z.string().max(21),
    id: z.string().min(1).max(21),
    languageTag: z.string().min(1).max(16),
    templateType: templateTypeGuard,
    details: emailTemplateDetailsGuard,
    createdAt: z.number(),
});
export const EmailTemplates = Object.freeze({
    table: 'email_templates',
    tableSingular: 'email_template',
    fields: {
        tenantId: 'tenant_id',
        id: 'id',
        languageTag: 'language_tag',
        templateType: 'template_type',
        details: 'details',
        createdAt: 'created_at',
    },
    fieldKeys: [
        'tenantId',
        'id',
        'languageTag',
        'templateType',
        'details',
        'createdAt',
    ],
    createGuard,
    guard,
    updateGuard: guard.partial(),
});
