// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
export var ApplicationType;
(function (ApplicationType) {
    ApplicationType["Native"] = "Native";
    ApplicationType["SPA"] = "SPA";
    ApplicationType["Traditional"] = "Traditional";
    ApplicationType["MachineToMachine"] = "MachineToMachine";
    ApplicationType["Protected"] = "Protected";
    ApplicationType["SAML"] = "SAML";
})(ApplicationType || (ApplicationType = {}));
export var OrganizationInvitationStatus;
(function (OrganizationInvitationStatus) {
    OrganizationInvitationStatus["Pending"] = "Pending";
    OrganizationInvitationStatus["Accepted"] = "Accepted";
    OrganizationInvitationStatus["Expired"] = "Expired";
    OrganizationInvitationStatus["Revoked"] = "Revoked";
})(OrganizationInvitationStatus || (OrganizationInvitationStatus = {}));
export var RoleType;
(function (RoleType) {
    RoleType["User"] = "User";
    RoleType["MachineToMachine"] = "MachineToMachine";
})(RoleType || (RoleType = {}));
export var SentinelActionResult;
(function (SentinelActionResult) {
    SentinelActionResult["Success"] = "Success";
    SentinelActionResult["Failed"] = "Failed";
})(SentinelActionResult || (SentinelActionResult = {}));
export var SentinelDecision;
(function (SentinelDecision) {
    SentinelDecision["Undecided"] = "Undecided";
    SentinelDecision["Allowed"] = "Allowed";
    SentinelDecision["Blocked"] = "Blocked";
    SentinelDecision["Challenge"] = "Challenge";
})(SentinelDecision || (SentinelDecision = {}));
export var SignInMode;
(function (SignInMode) {
    SignInMode["SignIn"] = "SignIn";
    SignInMode["Register"] = "Register";
    SignInMode["SignInAndRegister"] = "SignInAndRegister";
})(SignInMode || (SignInMode = {}));
export var AgreeToTermsPolicy;
(function (AgreeToTermsPolicy) {
    AgreeToTermsPolicy["Automatic"] = "Automatic";
    AgreeToTermsPolicy["ManualRegistrationOnly"] = "ManualRegistrationOnly";
    AgreeToTermsPolicy["Manual"] = "Manual";
})(AgreeToTermsPolicy || (AgreeToTermsPolicy = {}));
export var UsersPasswordEncryptionMethod;
(function (UsersPasswordEncryptionMethod) {
    UsersPasswordEncryptionMethod["Argon2i"] = "Argon2i";
    UsersPasswordEncryptionMethod["Argon2id"] = "Argon2id";
    UsersPasswordEncryptionMethod["Argon2d"] = "Argon2d";
    UsersPasswordEncryptionMethod["SHA1"] = "SHA1";
    UsersPasswordEncryptionMethod["SHA256"] = "SHA256";
    UsersPasswordEncryptionMethod["MD5"] = "MD5";
    UsersPasswordEncryptionMethod["Bcrypt"] = "Bcrypt";
    UsersPasswordEncryptionMethod["Legacy"] = "Legacy";
})(UsersPasswordEncryptionMethod || (UsersPasswordEncryptionMethod = {}));
