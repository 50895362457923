import { z } from 'zod';
export var VerificationType;
(function (VerificationType) {
    VerificationType["Password"] = "Password";
    VerificationType["EmailVerificationCode"] = "EmailVerificationCode";
    VerificationType["PhoneVerificationCode"] = "PhoneVerificationCode";
    VerificationType["Social"] = "Social";
    VerificationType["EnterpriseSso"] = "EnterpriseSso";
    VerificationType["TOTP"] = "Totp";
    VerificationType["WebAuthn"] = "WebAuthn";
    VerificationType["BackupCode"] = "BackupCode";
    VerificationType["NewPasswordIdentity"] = "NewPasswordIdentity";
    VerificationType["OneTimeToken"] = "OneTimeToken";
})(VerificationType || (VerificationType = {}));
export const verificationTypeGuard = z.nativeEnum(VerificationType);
