// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
import { z } from 'zod';
import { samlAttributeMappingGuard, samlAcsUrlGuard, samlEncryptionGuard, nameIdFormatGuard } from './../foundations/index.js';
const createGuard = z.object({
    applicationId: z.string().min(1).max(21),
    tenantId: z.string().max(21).optional(),
    attributeMapping: samlAttributeMappingGuard.optional(),
    entityId: z.string().max(128).nullable().optional(),
    acsUrl: samlAcsUrlGuard.nullable().optional(),
    encryption: samlEncryptionGuard.nullable().optional(),
    nameIdFormat: nameIdFormatGuard,
});
const guard = z.object({
    applicationId: z.string().min(1).max(21),
    tenantId: z.string().max(21),
    attributeMapping: samlAttributeMappingGuard,
    entityId: z.string().max(128).nullable(),
    acsUrl: samlAcsUrlGuard.nullable(),
    encryption: samlEncryptionGuard.nullable(),
    nameIdFormat: nameIdFormatGuard,
});
export const SamlApplicationConfigs = Object.freeze({
    table: 'saml_application_configs',
    tableSingular: 'saml_application_config',
    fields: {
        applicationId: 'application_id',
        tenantId: 'tenant_id',
        attributeMapping: 'attribute_mapping',
        entityId: 'entity_id',
        acsUrl: 'acs_url',
        encryption: 'encryption',
        nameIdFormat: 'name_id_format',
    },
    fieldKeys: [
        'applicationId',
        'tenantId',
        'attributeMapping',
        'entityId',
        'acsUrl',
        'encryption',
        'nameIdFormat',
    ],
    createGuard,
    guard,
    updateGuard: guard.partial(),
});
