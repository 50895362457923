export * from './config-form.js';
export * from './error.js';
export * from './metadata.js';
export * from './foundation.js';
export * from './passwordless.js';
export * from './social.js';
export * from './email-template.js';
export var DemoConnector;
(function (DemoConnector) {
    DemoConnector["Sms"] = "logto-sms";
    DemoConnector["Social"] = "logto-social-demo";
})(DemoConnector || (DemoConnector = {}));
export const demoConnectorIds = Object.freeze([
    DemoConnector.Sms,
    DemoConnector.Social,
]);
export var ServiceConnector;
(function (ServiceConnector) {
    ServiceConnector["Email"] = "logto-email";
})(ServiceConnector || (ServiceConnector = {}));
export const serviceConnectorIds = Object.freeze([ServiceConnector.Email]);
