import { z } from 'zod';
export const authRequestInfoGuard = z.object({
    issuer: z.string(),
    request: z.object({
        id: z.string(),
        destination: z.string().optional(),
        issueInstant: z.string(),
        assertionConsumerServiceUrl: z.string(),
    }),
});
