// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
import { z } from 'zod';
import { captchaConfigGuard } from './../foundations/index.js';
const createGuard = z.object({
    tenantId: z.string().max(21).optional(),
    id: z.string().min(1).max(128),
    config: captchaConfigGuard.optional(),
    createdAt: z.number().optional(),
    updatedAt: z.number().optional(),
});
const guard = z.object({
    tenantId: z.string().max(21),
    id: z.string().min(1).max(128),
    config: captchaConfigGuard,
    createdAt: z.number(),
    updatedAt: z.number(),
});
export const CaptchaProviders = Object.freeze({
    table: 'captcha_providers',
    tableSingular: 'captcha_provider',
    fields: {
        tenantId: 'tenant_id',
        id: 'id',
        config: 'config',
        createdAt: 'created_at',
        updatedAt: 'updated_at',
    },
    fieldKeys: [
        'tenantId',
        'id',
        'config',
        'createdAt',
        'updatedAt',
    ],
    createGuard,
    guard,
    updateGuard: guard.partial(),
});
