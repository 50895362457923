import { z } from 'zod';
export const oneTimeTokenContextGuard = z
    .object({
    jitOrganizationIds: z.string().array(),
})
    .partial();
export var OneTimeTokenStatus;
(function (OneTimeTokenStatus) {
    OneTimeTokenStatus["Active"] = "active";
    OneTimeTokenStatus["Consumed"] = "consumed";
    OneTimeTokenStatus["Revoked"] = "revoked";
    OneTimeTokenStatus["Expired"] = "expired";
})(OneTimeTokenStatus || (OneTimeTokenStatus = {}));
export const oneTimeTokenStatusGuard = z.nativeEnum(OneTimeTokenStatus);
