import { connectorMetadataGuard, googleOneTapConfigGuard, } from '@logto/connector-kit';
import { z } from 'zod';
import { SignInExperiences } from '../db-entries/index.js';
import { CaptchaType } from '../foundations/jsonb-types/index.js';
import { ssoConnectorMetadataGuard } from './sso-connector.js';
export const fullSignInExperienceGuard = SignInExperiences.guard.extend({
    socialConnectors: connectorMetadataGuard
        .omit({
        description: true,
        configTemplate: true,
        formItems: true,
        readme: true,
        customData: true,
    })
        .array(),
    ssoConnectors: ssoConnectorMetadataGuard.array(),
    forgotPassword: z.object({ phone: z.boolean(), email: z.boolean() }),
    isDevelopmentTenant: z.boolean(),
    googleOneTap: googleOneTapConfigGuard
        .extend({ clientId: z.string(), connectorId: z.string() })
        .optional(),
    captchaConfig: z
        .object({
        type: z.nativeEnum(CaptchaType),
        siteKey: z.string(),
    })
        .optional(),
});
